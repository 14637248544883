import { reactive, ref } from "vue";
import { list } from "@/api/coupon/couponRecordApi";
import useMessage from "@/hooks/useMessage";
import moment from "moment";
import { dateTimeFormat } from "@/utils/utils";
const useCouponVIP = () => {
  const { showErr } = useMessage();
  const loading = ref(false);
  const dataList = reactive({ list: [] });
  const total = ref(0);

  const couponVo = reactive({});

  const couponTypeNameEnum = {
    1: "折扣券",
    2: "金额券",
  };

  const useStates = ref([
    { value: 0, label: "未核销" },
    { value: 1, label: "已核销" },
  ]);

  const couponStates = [
    { value: 1, label: "未开始" },
    { value: 2, label: "领取中" },
    { value: 3, label: "已领完" },
    { value: 4, label: "已结束" },
  ];

  const couponStatesEnum = {
    NOT_STARTED: 1, // 未开始
    RECEIVING: 2, // 领取中
    COLLECTED: 3, // 已领完
    ENDED: 4, // 已结束
  };

  const columns = [
    {
      title: "序号",
      align: "center",
      width: 50,
      customRender: ({ index }) => {
        return index + 1;
      },
    },
    {
      title: "券包编号",
      align: "center",
      width: 200,
      dataIndex: "couponPackageId",
    },
    {
      title: "优惠券编号",
      align: "center",
      width: 200,
      dataIndex: "couponId",
    },
    {
      title: "名称",
      align: "center",
      width: 150,
      dataIndex: "couponPackageName",
    },
    {
      title: "券类型",
      align: "center",
      width: 80,
      dataIndex: "couponType",
      // eslint-disable-next-line no-unused-vars
      customRender: ({ _text, record }) => {
        return couponTypeNameEnum[record.couponType];
      },
    },
    {
      title: "券面值",
      align: "center",
      width: 80,
      dataIndex: "couponValue",
      // eslint-disable-next-line no-unused-vars
      customRender: ({ _text, record }) => {
        return `${record.couponValue}${record.couponType === 1 ? "折" : "元"}`;
      },
    },
    {
      title: "使用情况",
      align: "center",
      width: 80,
      dataIndex: "useState",
      // eslint-disable-next-line no-unused-vars
      customRender: ({ _text, record }) => {
        return `${
          record.useState === 1
            ? useStates.value[1].label
            : useStates.value[0].label
        }`;
      },
    },
    {
      title: "手机号",
      align: "center",
      width: 120,
      dataIndex: "userPhone",
    },
    {
      title: "车场名称",
      align: "center",
      width: 130,
      dataIndex: "parkName",
    },
    {
      title: "车牌号码",
      align: "center",
      width: 100,
      dataIndex: "carNumber",
    },
    {
      title: "领取时间",
      align: "center",
      width: 180,
      dataIndex: "getDate",
    },
    {
      title: "使用时间",
      align: "center",
      width: 180,
      dataIndex: "useDate",
      // eslint-disable-next-line no-unused-vars
      customRender: ({ _text, record }) => {
        return `${record.useDate ? record.useDate : "/"}`;
      },
    },
    {
      title: "订单号",
      align: "center",
      width: 180,
      dataIndex: "orderId",
    },
  ];
  const loadData = async (page, pageSize) => {
    loading.value = true;
    try {
      let res = await list({
        couponId: params.couponId || "",
        couponPackageId: params.couponPackageId || "",
        couponPackageName: params.couponPackageName || "",
        useState: params.useState,
        carNumber: params.carNumber || "",
        orderId: params.orderId || "",
        getStartDate: params.getStartDate || "",
        getEndDate: params.getEndDate || "",
        page,
        pageSize,
      });
      dataList.list = res.data;
      total.value = res.count;
      loading.value = false;
    } catch (e) {
      loading.value = false;
      await showErr(e);
    }
  };

  const date = new Date();
  const getStartDateDefault = moment(date.setDate(date.getDate() - 30)).format(
    dateTimeFormat,
  );
  const getEndDateDefault = moment(new Date()).format(dateTimeFormat);

  const params = reactive({
    couponId: "",
    couponPackageId: "",
    couponPackageName: "",
    useState: "",
    carNumber: "",
    orderId: "",
    getStartDate: getStartDateDefault,
    getEndDate: getEndDateDefault,
    getDate: [getStartDateDefault, getEndDateDefault],
  });

  const changeGetDate = dateArr => {
    params.getStartDate = moment(dateArr[0]).format(dateTimeFormat);
    params.getEndDate = moment(dateArr[1]).format(dateTimeFormat);
  };
  const changePubDate = dateArr => {
    couponVo.pubStartDate = moment(dateArr[0]).format(dateTimeFormat);
    couponVo.pubEndDate = moment(dateArr[1]).format(dateTimeFormat);
  };
  return {
    loading,
    params,
    dataList,
    total,
    columns,
    loadData,
    couponVo,
    useStates,
    couponStates,
    changeGetDate,
    changePubDate,
    couponStatesEnum,
  };
};
export default useCouponVIP;
